<template>
    <b-container class="homepage">
        <div class="box-wrap mb-4">
            <b-row class="is-multiline">
                <b-col cols="6">
                    <b-card class="token-box" no-body style="cursor: pointer" @click="showSelectNft()">
                        <div class="avatar">
                            <b-card-img style="image-rendering: pixelated" :src="nftLogo || require('../assets/images/nologo.svg')" alt="Image"></b-card-img>
                        </div>
                        <b-card-body>
                            <div class="token-info">
                                <b-card-text class="token-name" text-tag="span" >{{ utils.truncate(nftSymbol, 7) }}</b-card-text>
                                <b-icon icon="caret-down-fill"></b-icon>
                            </div>
                            <b-card-sub-title>ERC721</b-card-sub-title>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col cols="6">
                    <b-card class="token-box" no-body style="cursor: pointer" @click="showSelectFt()" img-width="60">
                        <div class="avatar icon-native">
                            <b-card-img :src="ftLogo || require('../assets/images/nologo.svg')" alt="Image"></b-card-img>
                        </div>
                        <b-card-body>
                            <div class="token-info">
                                <b-card-text class="token-name" text-tag="span" >{{ utils.truncate(ftSymbol, 7) }}</b-card-text>
                                <b-icon icon="caret-down-fill"></b-icon>
                            </div>
                            <b-card-sub-title>ERC20</b-card-sub-title>                            
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-card class="icon-arrow" no-body style="cursor: pointer">
                    <b-card-text>
                        <b-icon icon="arrow-left-right" font-scale="1.5"></b-icon>
                    </b-card-text>
                </b-card>
			</b-row>
            <b-row class="is-multiline mt-4" cols="2" cols-sm="2" cols-md="3" no-gutters>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size" >Items</b-card-text>
                        <b-card-text v-if="!loadingData"> {{ utils.bigToCommon(totalSupply, 0, 0) }} </b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size" >Stake Rate </b-card-text>
                        <b-card-text v-if="!loadingData" >{{ utils.feeToCommon(stakeRate) }}%</b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size">Exchange fee</b-card-text> 
                        <b-card-text v-if="!loadingData">{{ utils.feeToCommon(exchangeFee) }}%</b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size"> Withdraw fee </b-card-text> 
                        <b-card-text v-if="!loadingData"> {{ utils.feeToCommon(withdrawFee) }}%</b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size"> Treasury </b-card-text> 
                        <b-card-text v-if="!loadingData"> {{ utils.feeToCommon(treasuryFee) }}%</b-card-text> 
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size"> TVL </b-card-text> 
                        <b-card-text v-if="!loadingData"> {{ utils.bigToLabel(tvl, ftDecimals, 2) }} {{ ftSymbol }}</b-card-text> 
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="is-multiline" cols="1">
                <b-col class="mt-3">
                    <router-link custom v-slot="{ navigate }" :to="{ name: 'market', params: { ft: ftLink, nft: nftToken, chainId: chainId }}" style="cursor: pointer" v-if="!isCreate && !isConnect">
                        <b-button class="button btn-go-market" style="width: 100%" @click="navigate" @keypress.enter="navigate">Go to {{ nftSymbol }}-{{ ftSymbol }} market</b-button>
                    </router-link>
                    <b-button class="button btn-wallet-connect" style="width: 100%" v-if="isConnect" @click="connect()">Connect to a wallet</b-button>
                    <b-button class="button btn-create-market" :disabled="isCreateDisabled" style="width: 100%; background-color: #196EC2" v-if="isCreate && !isConnect" @click="isCreateDisabled = true;create()">Create {{ nftSymbol }}-{{ ftSymbol }} market</b-button>
                </b-col>
            </b-row>
		</div>
        <div>
            <b-modal id="select-nft" no-close-on-backdrop centered hide-footer @hidden="watchSearchNft = ''" title="Select a collection">
                <div style="min-height: 200px" >
                    <b-form-group
                     label-for="search-nft-token"
                     style="position: relative"
                     >
                     <b-form-input
                     id="search-nft-token"
                     class="search-token"
                     type="text"
                     placeholder="Paste an address"
                     v-model.lazy="watchSearchNft"
                     required
                     ></b-form-input>
                     <b-icon class="icon-search" icon="search"></b-icon>
                    </b-form-group>

                    <b-container v-for="(token, key) in tokenNfts" :key="token.symbol">
                        <b-row no-gutter justify-content="space-between" align-items="center" class="mb-3">
                            <b-col class="is-flex px-0" style="align-items: center;"> 
                                <b-img class="token-icon mr-2" :src="token.logoURI || require('../assets/images/nologo.svg')"></b-img>                                
                                <div>
                                    <b-card-title class="token-symbol">
                                        {{ token.symbol }}
                                    </b-card-title>
                                    <b-card-text class="token-name">{{ token.name }}</b-card-text>
                                    <b-card-text class="token-remove" v-if="token.imported" @click="removeToken(token)">Remove</b-card-text>
                                </div>
                            </b-col>
                            <b-col><b-card-text v-if="token.balance">{{ utils.bigToCommon(token.balance, token.decimals, 6) }}</b-card-text></b-col>
                            <div><b-button @click="importNft(key)" class="token-import">Import</b-button></div>
                        </b-row>
                    </b-container>
                </div>
            </b-modal>
        </div>
        <div>
            <b-modal id="select-ft" no-close-on-backdrop centered hide-footer @hidden="watchSearchFt = ''" title="Select a token">
                <div style="min-height: 200px" >
                    <b-form-group
                     label-for="search-ft-token"
                     style="position: relative"
                     >
                     <b-form-input
                     id="search-ft-token"
                     class="search-token"
                     type="text"
                     v-model.lazy="watchSearchFt"
                     placeholder="Paste an address"
                     required
                     ></b-form-input>
                     <b-icon class="icon-search" icon="search"></b-icon>
                    </b-form-group>
                    <b-container v-for="(token, key) in tokenFts" :key="token.symbol">
                        <b-row no-gutter justify-content="space-between" align-items="center" class="mb-3">
                            <b-col class="is-flex px-0" style="align-items: center;">
                                <b-img class="token-icon mr-2" :src="token.logoURI || require('../assets/images/nologo.svg')"></b-img>  
                                <div>
                                    <b-card-title class="token-symbol">{{ token.symbol }}</b-card-title>
                                    <b-card-text class="token-name">{{ token.name }}</b-card-text>
                                    <b-card-text class="token-remove" v-if="token.imported" @click="removeToken(token)">Remove</b-card-text>
                                </div>
                            </b-col>
                            <b-col><b-card-text  v-if="token.balance">{{ utils.bigToCommon(token.balance, token.decimals, 6) }}</b-card-text ></b-col>
                            <div><b-button @click="importFt(key)" class="token-import">Import</b-button></div>
                        </b-row>
                    </b-container>
                </div>
            </b-modal>
            <b-modal id="import-token-alert" no-close-on-backdrop centered hide-footer title="Import tokens">
                <ImportTokenAlert @import-success="$bvModal.hide('import-token-alert')" :tokens="{ ft: ftToken, nft: nftToken }"/>
            </b-modal>
        </div>
        <b-toast id="tx-confirm" title="Transaction Process" variant="info" solid no-auto-hide>
            Your transaction is being processed.
        </b-toast>
        <b-toast id="tx-success" title="Transaction Success" variant="success" solid auto-hide-delay="8000">
            Your transaction is successful.
        </b-toast>

    </b-container>
</template>
<script>
import { ethers } from 'ethers'
import { Factory, FT, NFT, Multicall } from '@baza/sdk'
import EventBus from '../eventBus'
import store from '../stores'
import ImportTokenAlert from './ImportTokenAlert.vue'

export default {
    components: {
        ImportTokenAlert
    },
    data: () => ({
        watchSearchNft: '',
        watchSearchFt: '',
        nftToken: '',
        ftToken: '',
        ftLink: '',
        nftSymbol: '',
        nftLogo: '',
        ftSymbol: '',
        nftName: 'BAZAPET',
        ftName: 'BUSD',
        ftDecimals: 18,
        ftLogo: '',
        sFt: '',
        sNft: '',
        market: '',
        tokenNfts: [],
        tokenFts: [],
        isCreate: false,
        totalSupply: 0,
        exchangeFee: 0,
        withdrawFee: 0,
        treasuryFee: 0,
        stakeRate: 0,
        tvl: 0,
        isConnect: false,
        isCreateDisabled: false,
        loadingData: true
    }),
    created: async function () {
        try {
            this.provider = await this.getProvider(parseInt(this.$route.params.chainId))

            this.isConnect = (this.provider.connection.url !== 'metamask')
            this.address = await this.provider.getSigner().getAddress()
        } catch(e) {
            this.isConnect = true
        }
        this.factory = await Factory.setProvider(this.provider)
        let tokens = store.getTokens()
        this.tokenFts = tokens.filter(t => (t.standard === 'ERC20' && t.chainId === this.chainId))
        this.tokenNfts = tokens.filter(t => (t.standard === 'ERC721' && t.chainId === this.chainId))
        if (this.tokenNfts.length > 0) {
            this.importNft(0)
        }
        if (this.tokenFts.length > 0) {
            this.importFt(0)
        }
        this.loadingData = false

    },
    watch: {
        async watchSearchNft (sNft) {
            let tokens = store.getTokens()
            let isAddress = false
            try {
                isAddress = !!ethers.utils.getAddress(sNft)
            } catch (e) {
                isAddress = false
            }

            this.tokenNfts = tokens.filter(t => {
                let b = (t.standard === 'ERC721' && t.chainId === this.chainId)
                return b && (t.symbol.toLowerCase().includes(sNft.toLowerCase())
                    || t.name.toLowerCase().includes(sNft.toLowerCase())
                    || t.address.toLowerCase().includes(sNft.toLowerCase())
                )
            })

            if (isAddress) {
                let nft = new NFT(sNft, this.provider)
                let symbol = await nft.symbol()
                let name = await nft.name()
                this.tokenNfts = [{ name, symbol, address: sNft }]
            }
        },
        async watchSearchFt (sFt) {
            let tokens = store.getTokens()
            let isAddress = false
            try {
                isAddress = ethers.utils.getAddress(sFt)
            } catch (e) {
                isAddress = false
            }

            this.tokenFts = tokens.filter(t => {
                let b = (t.standard === 'ERC20' && t.chainId === this.chainId)
                return b && (t.symbol.toLowerCase().includes(sFt.toLowerCase())
                    || t.name.toLowerCase().includes(sFt.toLowerCase())
                    || t.address.toLowerCase().includes(sFt.toLowerCase())
                )
            })

            if (isAddress) {
                let ft = new FT(sFt, this.provider)
                let symbol = await ft.symbol()
                let name = await ft.name()
                this.tokenFts = [{ name, symbol, address: sFt }]
            }
        },
    },
    methods: {
        async create () {
            try {
                let tx = await this.factory.createMarket(this.ftToken, this.nftToken)
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                this.isCreate = false
            } catch(e) {
                this.isCreateDisabled = false
            }
        },
        async getMarketInfo() {
            let calls = await Multicall.setMulticall(this.ftToken, this.nftToken, this.provider)
            let { totalSupply, exchangeFee, withdrawFee, treasuryFee, stakeRate, tvl, ftDecimals } = await calls.getMarketInfo()
            this.totalSupply = totalSupply
            this.exchangeFee = exchangeFee
            this.withdrawFee = withdrawFee
            this.treasuryFee = treasuryFee
            this.stakeRate = stakeRate
            this.tvl = tvl
            this.ftDecimals = ftDecimals
        },
        async importNft (i) {
            this.nftToken = this.tokenNfts[i].address
            this.nftSymbol = this.tokenNfts[i].symbol
            this.nftName = this.tokenNfts[i].name
            this.nftLogo = this.tokenNfts[i].logoURI
            if (this.ftToken && this.nftToken) {
                this.market = await this.factory.getMarket(this.ftToken, this.nftToken)
                this.isCreate = ethers.constants.AddressZero === this.market
                if (!this.isCreate) {
                    this.getMarketInfo()
                } else {
                    let nft = new NFT(this.nftToken, this.provider)
                    this.totalSupply = await nft.totalSupply()
                    this.exchangeFee = 0
                    this.withdrawFee = 0
                    this.treasuryFee = 0
                    this.stakeRate = 0
                    this.tvl = 0
                }
            }

            this.$bvModal.hide('select-nft')
            let b = this.utils.checkTokenAlert(this.chainId, this.ftToken, this.nftToken)
            if (b) {
                this.$bvModal.show('import-token-alert')
            }
            let tokens = store.getTokens()
            this.tokenNfts = tokens.filter(t => (t.standard === 'ERC721' && t.chainId === this.chainId))
            this.sNft = ''
        },
        async importFt (i) {
            this.loadingData = true
            this.ftToken = this.tokenFts[i].address
            this.ftSymbol = this.tokenFts[i].symbol
            this.ftName = this.tokenFts[i].name
            this.ftLogo = this.tokenFts[i].logoURI
            this.ftLink = this.ftToken
            if (this.ftToken === this.network.nativeCurrency.wrappedToken) {
                this.ftLink = this.network.nativeCurrency.symbol
            }

            if (this.ftToken && this.nftToken) {
                this.market = await this.factory.getMarket(this.ftToken, this.nftToken)
                this.isCreate = ethers.constants.AddressZero === this.market
                if (!this.isCreate) {
                    this.getMarketInfo()
                } else {
                    let nft = new NFT(this.nftToken, this.provider)
                    this.totalSupply = await nft.totalSupply()
                    this.exchangeFee = 0
                    this.withdrawFee = 0
                    this.treasuryFee = 0
                    this.stakeRate = 0
                    this.tvl = 0
                }
            }
            this.$bvModal.hide('select-ft')
            let b = this.utils.checkTokenAlert(this.chainId, this.ftToken, this.nftToken)
            if (b) {
                this.$bvModal.show('import-token-alert')
            }
            let tokens = store.getTokens()
            this.tokenFts = tokens.filter(t => (t.standard === 'ERC20' && t.chainId === this.chainId))
            this.sFt = ''
            this.loadingData = false
        },
        showSelectNft () {
            let tokens = store.getTokens()
            this.tokenNfts = tokens.filter(t => (t.standard === 'ERC721' && t.chainId === this.chainId))
            this.$bvModal.show('select-nft')
        },
        showSelectFt () {
            let tokens = store.getTokens()
            this.tokenFts = tokens.filter(t => (t.standard === 'ERC20' && t.chainId === this.chainId))
            this.$bvModal.show('select-ft')
        },
        connect () {
            EventBus.$emit('connect')
        },
        removeToken (token) {
            let tokens = store.removeToken(token)
            this.tokenNfts = tokens.filter(t => (t.standard === 'ERC721' && t.chainId === this.chainId))
            this.tokenFts = tokens.filter(t => (t.standard === 'ERC20' && t.chainId === this.chainId))
        }
    }


};
</script>

<style lang="scss">
    .container.homepage{
        width: 100%;
        max-width: 500px;
        margin-top: 40px;
    }
    .box-wrap{
        background-color: #fff;
        padding: 24px;
        border-radius: 16px;
        .is-multiline{
            position: relative;
        }        
        .icon-arrow{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            z-index: 1;
            background-color: #F4F5F7;
            border-color: #fff;
            border-width: 4px;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
        }
        .t-size{
            font-size:  12px;
            color: #7A869A;
        }
        .token-box{
            background-color: #F4F5F7;
            border: none;
            padding: 16px;
            border-radius: 8px;
            @media(max-width: 499px){
                .card-body{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .token-info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .token-name{
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 5px;
            }
        }
        .avatar{
            width: 100px;
            height: 100px;
            border-radius: 100%;
            position: relative;
            padding: 5px;
            margin: 0 auto;
            overflow: hidden;
            img{
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
        .card-item {
            border-radius: 8px;
        }
        .button{
            padding: 11px;
            border-radius: 8px;
            border: none;
            &.btn-go-market,&.btn-create-market{
                background-color:#9D22C1;
                &:hover{
                    background-color: #b62adf;
                }
            }
            &.btn-wallet-connect{
                background: #DE350B;
                &:hover{
                    background-color: #e53b11;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            
        }
    }
    #select-nft,#select-ft{
        .modal-content{
            border-radius: 16px;
            overflow: hidden;
        }
        .modal-header{
            background
            : linear-gradient(243.18deg, #f4d4fd 0%, #ffffff 100%);
            justify-content: center;
            .modal-title{
                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }
        }
        .token-icon{
            width: 40px;
            border-radius: 100%;

        }
        .close{
            position: absolute;
            right: 20px;
            top: 15px;
        }
        .search-token{
            height: 56px;
            padding-left: 40px;
            margin-bottom: 30px;
            border-radius: 8px;
        }
        .icon-search{
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
        }
        .token-symbol{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0;
        }
        .token-name{
            color: #7A869A;
            font-size: 12px;
            margin-bottom: 0;
        }
        .token-remove{
            color: #9D22C1;
            font-size: 12px;
            margin-bottom: 0;
            cursor: pointer;
        }
        .token-import{
            background-color: rgba(25, 110, 194, 0.1);
            border: none;
            color: #196EC2;
            font-weight: 700;
            border-radius: 8px;
        }
    }    
    
</style>
