
import Home from './components/Home.vue'
/*
import Pet from './components/Pet/Pet.vue'
import PublishPet from './components/Pet/PublishPet.vue'
import UnpublishPet from './components/Pet/UnpublishPet.vue'
import UpdatePet from './components/Pet/UpdatePet.vue'
import BidPet from './components/Pet/BidPet.vue'
import MyPets from './components/Pet/MyPets.vue'
import PetMarket from './components/Pet/Market.vue'
import Mint from './components/Pet/Mint.vue'
*/
import Detail from './components/Detail.vue'
import Bid from './components/Bid.vue'
import Ask from './components/Ask.vue'
import UpdateAsk from './components/UpdateAsk.vue'
import UpdateBid from './components/UpdateBid.vue'
import Market from './components/Market.vue'
import Account from './components/Account.vue'
import FAQ from './components/FAQ.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/faq', component: FAQ },
    { path: '/:chainId/faq', component: FAQ },
    { path: '/account', component: Account },
    { path: '/:chainId/account', component: Account, name: 'account' },
    { path: '/:chainId', component: Home, name: 'home' },
    { path: '/:chainId/market/:ft/:nft', component: Market, name: 'market' },
    { path: '/market/:ft/:nft', component: Market },
    { 
        path: '/market/:ft/:nft/:id',
        name: 'detaildefault',
        component: Detail
    },
    { 
        path: '/:chainId/market/:ft/:nft/:id',
        name: 'detail',
        component: Detail
    },
    { path: '/bid/:ft/:nft/:id', component: Bid },
    { path: '/:chainId/bid/:ft/:nft/:id', component: Bid, name: 'bid' },
    { path: '/bid/update/:ft/:nft/:id', component: UpdateBid },
    { path: '/:chainId/bid/update/:ft/:nft/:id', component: UpdateBid, name: 'updatebid' },
    { path: '/ask/:ft/:nft/:id', component: Ask },
    { path: '/:chainId/ask/:ft/:nft/:id', component: Ask, name: 'ask' },
    { path: '/ask/update/:ft/:nft/:id', component: UpdateAsk },
    { path: '/:chainId/ask/update/:ft/:nft/:id', component: UpdateAsk, name: 'updateask' },
    /*
    { path: '/pet/mint', component: Mint },
    { path: '/pet/market', component: PetMarket },
    { path: '/pet/:id', component: Pet },
    { path: '/pet/publish/:id', component: PublishPet },
    { path: '/pet/unpublish/:id', component: UnpublishPet },
    { path: '/pet/update/:id', component: UpdatePet },
    { path: '/pet/bid/:id', component: BidPet },
    { path: '/mypets', component: MyPets },
    */
]

export default routes
